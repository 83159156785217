div.home {
  color: #fff !important;
  font-family: "Ubuntu Mono", monospace;
  background-color: #1e3a3a;
  padding: 30px;
}

.centeredContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

button.contact_me {
  color: #00adb5 !important;
  text-decoration: none !important;
  font-weight: bolder;
  background: none;
  font-family: inherit;
}

button.hire_me {
  color: #32ff7e !important;
  text-decoration: none !important;
  font-weight: bolder;
  background: none;
  font-family: inherit;
}

.contact_me_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

h2.subtitle {
  margin-top: 10px;
  color: #f4a261 !important;
}

h3.description {
  margin-top: 30px;
  color: #f5deb3 !important;
}

span.links {
  padding: 10px;
}
