.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalContent {
  background-color: #c6c6c6;
  margin: auto;
  padding: 20px;
  border: 1px solid #cccccc;
  width: 80%;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.contactForm {
  display: flex;
  flex-direction: column;
}

.formLabel {
  margin-bottom: 8px;
  font-weight: bold;
  color: black;
}

.formInput,
.formTextarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.formInput:focus,
.formTextarea:focus {
  border-color: #007bff;
  outline: none;
}

.formButton {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.formButton:hover {
  background-color: #0056b3;
}

.success-message {
  text-align: center;
  color: green;
  font-size: 18px;
}
